import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'auth/signin',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      //{
      //  path: 'about-us',
      //  loadChildren: () => import('./demo/pages/authentication/about-us/about-us.module').then(m => m.AboutUsModule)
      //},
      //{
      //  path: 'privacy-policy',
      //  loadChildren: () => import('./demo/pages/authentication/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      //},
      //{
      //  path: 'terms-and-conditions',
      //  loadChildren: () => import('./demo/pages/authentication/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule)
      //},
      //{
      //  path: 'refund-policy',
      //  loadChildren: () => import('./demo/pages/authentication/refund-policy/refund-policy.module').then(m => m.RefundPolicyModule)
      //}
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'dashboard/default',
      //   pathMatch: 'full'
      // },       
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]
      },
      //{
      //  path: 'basic',
      //  loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(m => m.UiBasicModule)
      //},
      //{
      //  path: 'forms',
      //  loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(m => m.FormElementsModule)
      //},
      //{
      //  path: 'tables',
      //  loadChildren: () => import('./demo/pages/tables/tables.module').then(m => m.TablesModule)
      //},
      //{
      //  path: 'charts',
      //  loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(m => m.CoreChartModule)
      //},
      //{
      //  path: 'sample-page',
      //  loadChildren: () => import('./demo/main/sample-page/sample-page.module').then(m => m.SamplePageModule)
      //},
      {
        path: 'slider',
        loadChildren: () => import('./demo/main/slider/slider.module').then(m => m.SliderModule), canActivate: [AuthGuard]
      },
      {
        path: 'user',
        loadChildren: () => import('./demo/main/user/user.module').then(m => m.UserModule), canActivate: [AuthGuard]
      },
      {
        path: 'product',
        loadChildren: () => import('./demo/main/product/product.module').then(m => m.ProductModule), canActivate: [AuthGuard]
      },
      {
        path: 'collection',
        loadChildren: () => import('./demo/main/collection/collection.module').then(m => m.CollectionModule), canActivate: [AuthGuard]
      },
      {
        path: 'closed-collection',
        loadChildren: () => import('./demo/main/closed-collection/closed-collection.module').then(m => m.ClosedCollectionModule), canActivate: [AuthGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('./demo/main/account/account.module').then(m => m.AccountModule), canActivate: [AuthGuard]
      },
      {
        path: 'shop',
        loadChildren: () => import('./demo/main/shop/shop.module').then(m => m.ShopModule), canActivate: [AuthGuard]
      },
      {
        path: 'pending-shop',
        loadChildren: () => import('./demo/main/pending-shop/pending-shop.module').then(m => m.PendingShopModule), canActivate: [AuthGuard]
      },
      {
        path: 'employee',
        loadChildren: () => import('./demo/main/employee/employee.module').then(m => m.EmployeeModule), canActivate: [AuthGuard]
      },
      {
        path: 'employee-track',
        loadChildren: () => import('./demo/main/employee-track/employee-track.module').then(m => m.EmployeeTrackModule), canActivate: [AuthGuard]
      },

      {
        path: 'sales-report',
        loadChildren: () => import('./demo/main/report/sales-report/sales-report.module').then(m => m.SalesReportReportModule), canActivate: [AuthGuard]
      },
      {
        path: 'credit-bill-report',
        loadChildren: () => import('./demo/main/report/credit-bill-report/credit-bill-report.module').then(m => m.CreditBillReportReportModule), canActivate: [AuthGuard]
      },
      {
        path: 'daily-collection-report',
        loadChildren: () => import('./demo/main/report/daily-collection-report/daily-collection-report.module').then(m => m.DailyCollectionReportReportModule), canActivate: [AuthGuard]
      },
      {
        path: 'deduction-report',
        loadChildren: () => import('./demo/main/report/deduction-report/deduction-report.module').then(m => m.DeductionReportModule), canActivate: [AuthGuard]
      },
      {
        path: 'customer-summery-report',
        loadChildren: () => import('./demo/main/report/customer-summery-report/customer-summery-report.module').then(m => m.CustomerSummeryReportModule), canActivate: [AuthGuard]
      },
      //{
      //  path: 'summery-report',
      //  loadChildren: () => import('./demo/main/report/summery-report/summery-report.module').then(m => m.SummeryReportModule), canActivate: [AuthGuard]
      //},
      {
        path: 'changepassword',
        loadChildren: () => import('./demo/main/changepassword/changepassword.module').then(m => m.ChangePasswordModule), canActivate: [AuthGuard]
      },
      {
        path: 'demo',
        loadChildren: () => import('./demo/main/demo/demo.module').then(m => m.DemoModule), canActivate: [AuthGuard]
      },
      {
        path: 'paymentrequest',
        loadChildren: () => import('./demo/main/paymentrequest/paymentrequest.module').then(m => m.paymentrequestModule), canActivate: [AuthGuard]
      },
      {
        path: 'acceptedpaymentrequest',
        loadChildren: () => import('./demo/main/acceptedpaymentrequest/acceptedpaymentrequest.module').then(m => m.acceptedpaymentrequestModule), canActivate: [AuthGuard]
      },
      {
        path: 'goldloanrequest',
        loadChildren: () => import('./demo/main/goldloanrequest/goldloanrequest.module').then(m => m.goldloanrequestModule), canActivate: [AuthGuard]
      },
      {
        path: 'goldsellingrequest',
        loadChildren: () => import('./demo/main/goldsellingrequest/goldsellingrequest.module').then(m => m.goldsellingrequestModule), canActivate: [AuthGuard]
      },





      {
        path: 'entry-user',
        loadChildren: () => import('./demo/main/entry-user-management/entry-user-management.module').then(m => m.EntryUserManagementModule), canActivate: [AuthGuard]
      },



    ]
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
