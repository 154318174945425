<ul class="navbar-nav">
  <!--<li>
    <div class="dropdown" ngbDropdown (openChange)="onChange($event)" placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i><span *ngIf="newOrderCount>0" class="badge badge-info">{{newOrderCount}}</span></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
          </div>
        </div>
        <ul class="noti-body">

         <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>now</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>10 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>
        </ul>
       <div class="noti-footer">
          <a [routerLink]="['/order/pending']">show all</a>
        </div>
        <ul class="noti-body">
          <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>now</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>10 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="noti-footer">
          <a href="javascript:">show all</a>
        </div>
      </div>
    </div>
  </li>-->
  <li>
    <div class="dropdown" style="visibility: hidden;" ngbDropdown (openChange)="onChange($event)" placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i><span *ngIf="notificationCount>0" class="badge badge-info">{{notificationCount}}</span></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
            <!--<a href="javascript:" (click)="onClickMarkAsRead()" class="m-r-10">mark as read</a>
            <a href="javascript:" (click)="onClickMarkAsRead()">clear all</a>-->
            <!--<a *ngIf="this.orderNotificationList.length>0" style="cursor:pointer" (click)="onViewAll()">view all</a>-->
            <!--<a *ngIf="orderNotificationList.length>0" style="cursor:pointer" (click)="onClearAll()">clear all</a>-->
          </div>
        </div>

        <ul class="noti-body">
          <!--<li class="n-title">
            <p class="m-b-0">NEW</p>
          </li>-->
          <li class="notification" *ngFor="let notification of notificationList">
            <div class="media" style="cursor: pointer;" (click)="onViewNotification(notification)">
              <!--<img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">-->
              <img class="img-radius" src="assets/images/notification/notification.png" alt="image">
              <div class="media-body">
                <p>
                  <strong>From {{notification.investorName}}</strong>
                  <!--<span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>now</span>-->
                  <!--<a (click)="onClickMarkAsRead(notification)">
                    <span class="n-time text-muted"><i class="icon feather icon-eye m-r-10"></i>view</span>
                  </a>-->
                </p>

                <!--<p>New order placed by {{notification.orderedByName}}</p>-->
                <p>{{notification.message}}</p>
                <p *ngIf="notification.type==1"><small>Invested On {{notification.createdDateTime | date:'medium'}}</small></p>
                <p *ngIf="notification.type==2"><small>Requested On {{notification.createdDateTime | date:'medium'}}</small></p>
              </div>
            </div>
          </li>

          <li class="notification" *ngIf="notificationList?.length==0">
            <div class="media">
              <!--<img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">-->
              <img class="img-radius" src="assets/images/notification/notification.png" alt="Generic placeholder image">
              <div class="media-body">
                <!--<p>
                  <strong>Order#</strong>
                  <span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>now</span>
                </p>-->
                <p>Notifications Not Found..!</p>
              </div>
            </div>
          </li>

          <!--<li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>now</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>10 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>-->
        </ul>
        <!--<div class="noti-footer">
          <a [routerLink]="['/order/pending']">show all</a>
        </div>-->
        <!--<ul class="noti-body">
          <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>now</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>10 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="noti-footer">
          <a href="javascript:">show all</a>
        </div>-->
      </div>
    </div>
  </li>

  <li>
    <div class="dropdown drp-user" ngbDropdown placement='auto'>
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-settings"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image">
          <!--<span>John Doe</span>-->
          <span>{{currentUserDetails.fullName}}</span>
          <!--<a href="javascript:" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>-->
          <a (click)="DoLogOut()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <!--<ul class="pro-body">
          <li><a [routerLink]="['/setting']" class="dropdown-item"><i class="feather icon-settings"></i> Settings</a></li>
         <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li>
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a></li>
        </ul>-->
      </div>
    </div>
  </li>
</ul>
<audio #myAudio src="assets/audio/alert.wav"></audio>
