import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  //  template: `
  //<div class="form-group">
  //   <label>{{to.label}}</label>
  //   <input class="form-control" (keypress)="numberOnly($event)" maxLength="10" type="text" [formControl]="formControl" [formlyAttributes]="field">
  //</div>
  // `,

  template: `
    <div class="form-group" [class.error]="showError">
<label>{{to.label}}</label>
       <input class="form-control" (keypress)="numberOnly($event)" maxLength="10" type="text" [formControl]="formControl" [formlyAttributes]="field">
      <formly-validation-message *ngIf="showError"
        [fieldForm]="formControl" 
        [field]="field"></formly-validation-message>
    </div>
 `,
  styles: [`
  .error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .error input {
    border-color: #dc3545;
  }
 `]

})
export class FormlyFieldMobile extends FieldType {

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
