import { Injectable } from "@angular/core";
import { of, observable, Observable, forkJoin } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
//import { FormlyFieldConfig } from "@ngx-formly/core";
import { LoaderEnabled } from '../../demo/components/loader/loader.service';
//const baseURL = "https://localhost:5001/api/Product/";
const baseURL = "api/slider/";
//import { environment } from '../../../environments/environment';
//const baseURL = environment.baseUrl +"/api/slider/";

@Injectable({
    providedIn: 'root'
})
export class SliderService {

    constructor(private httpClient: HttpClient) { }

    @LoaderEnabled()
    getAll(): Observable<any> {
        return this.httpClient.get(baseURL);
    }
    @LoaderEnabled()
    get(id): Observable<any> {
        return this.httpClient.get(baseURL + id);
    }
    @LoaderEnabled()
    create(data): Observable<any> {
        //debugger
        //return this.httpClient.put(`${baseURL}/${id}`, data);
        const httpOptions = {
            headers: new HttpHeaders({ "Content-Type": "application/json" })
        };
        return this.httpClient.post(baseURL, data, httpOptions).pipe(
            tap((data: any) => this.log("updated")),
            catchError(this.handleError<any>(""))
        );
    }
    @LoaderEnabled()
    update(data): Observable<any> {
        //debugger
        //return this.httpClient.put(`${baseURL}/${id}`, data);
        const httpOptions = {
            headers: new HttpHeaders({ "Content-Type": "application/json" })
        };
        return this.httpClient.put(baseURL, data, httpOptions).pipe(
            tap((data: any) => this.log("updated")),
            catchError(this.handleError<any>(""))
        );
    }
    @LoaderEnabled()
    delete(id): Observable<any> {
        //return this.httpClient.delete(`${baseURL}/${id}`);
        const httpOptions = {
            headers: new HttpHeaders({ "Content-Type": "application/json" })
        };
        //return this.http.post("/api/connector/deleteConnector", connector, httpOptions).pipe(
        return this.httpClient.delete(baseURL + id).pipe(
            tap((id: any) => this.log("deleted")),
            catchError(this.handleError<any>(""))
        );
    }
    @LoaderEnabled()
    deleteAll(): Observable<any> {
        return this.httpClient.delete(baseURL);
    }

    searchByName(name): Observable<any> {
        return this.httpClient.get(`${baseURL}?name=${name}`);
    }

    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

    private log(message: string) {
        console.log("SliderService: " + message);
    }
}
