<!--<div class="widget form-group">
  <input id="typeahead-basic"
         type="text" class="form-control" autocomplete="off"
         [formControl]="formControl"
         [formlyAttributes]="field"
         [typeahead]="search$"
         [typeaheadItemTemplate]="autocompleteItem"
         [typeaheadAsync]="true"
         [typeaheadOptionField]="prop" />
  Works with typeaheadOptionField="value.nested"

<ng-template #autocompleteItem let-item="item">
  <span>{{ item.value.nested }}</span>
</ng-template>-->

<!--<div class="widget form-group">
  <input [typeahead]="search$"
         [typeaheadItemTemplate]="autocompleteItem"
         [typeaheadAsync]="true"
         [typeaheadOptionField]="to.searchText"
         [typeaheadMinLength]="0"
         typeaheadOptionsLimit=2000
         [typeaheadOptionsInScrollableView]="10"
         [typeaheadScrollable]="true"
         type="text" class="form-control" autocomplete="off"
         [formControl]="formControl"
         [formlyAttributes]="field" />
</div>

<ng-template #autocompleteItem let-item="item">
  <span>{{ item[to.searchText] }}</span>
</ng-template>-->

<div class="widget form-group">
  <input 
         [typeahead]="to.data" [formControl]="formControl" [typeaheadMinLength]="0"
         class="form-control" autocomplete="off" [formlyAttributes]="field"
         [typeaheadOptionsInScrollableView]="10"
         [typeaheadScrollable]="true"
         >
</div>

<!--<div class="widget form-group">
  <input [formControl]="formControl"
         [typeahead]="states"
         [typeaheadAsync]="true"
         [typeaheadMinLength]="0"
         typeaheadOptionsLimit=2000
         [typeaheadOptionsInScrollableView]="10"
         [typeaheadScrollable]="true"
         type="text" class="form-control" autocomplete="off"
         [formlyAttributes]="field">
</div>-->
