<div class="navbar-brand header-logo">
  <a [routerLink]="['/dashboard/default/']" class="b-brand">
    <!--<div class="b-bg">
      <i class="feather icon-trending-up"></i>
    </div>-->
    <!--<span class="b-title">Datta Able</span>-->
    <span class="b-title">JEWEL TRENDZ ADMIN</span>
  </a>
  <a href="javascript:" class="mobile-menu" [ngClass]="{'on' : navCollapsed}" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
</div>
