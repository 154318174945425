import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, AbstractControl } from '@angular/forms';

import { AlertModule, BreadcrumbModule, CardModule, ModalModule } from './components';
import { DataFilterPipe } from './components/data-table/data-filter.pipe';
import { TodoListRemoveDirective } from './components/todo/todo-list-remove.directive';
import { TodoCardCompleteDirective } from './components/todo/todo-card-complete.directive';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';
import { SpinnerComponent } from './components/spinner/spinner.component';

import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { DataTablesModule } from "angular-datatables";

// ANgular Rich Text Editor
import { AngularEditorModule } from '@kolkov/angular-editor';


// Formly Module
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
//import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FormlyFieldButton } from '../../shared/formly-custom-controls/button/button-type.component';
import { FormlyFieldFile } from '../../shared/formly-custom-controls/fileupload/file-type.component';
import { FileValueAccessor } from '../../shared/formly-custom-controls/fileupload/file-value-accessor';
import { FormlyFieldDatePicker } from '../../shared/formly-custom-controls/datepicker/date-picker.component';
import { FormlyFieldDateTimePicker } from '../../shared/formly-custom-controls/datetimepicker/datetime-picker.component';
import { PanelWrapperComponent } from '../../shared/formly-custom-controls/panel/panel-wrapper.component';
import { FormlyFieldTextEditor } from '../../shared/formly-custom-controls/texteditor/text-editor.component';
import { FormlyFieldMobile } from '../../shared/formly-custom-controls/mobile/mobile.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormlyFieldAutocompleteComponent } from '../../shared/formly-custom-controls/autocomplete/autocomplete.component';
import { FormlyFieldNgSelect } from '../../shared/formly-custom-controls/ng-select/ng-select.component';

import { MaterialModule } from '../../my-material.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { MustMatchDirective } from '../../shared/directive/must-match.directive';

import { MustMatchDirectiveModule } from "../../shared/directive/must-match.module";
import { TargetBlankModule } from "../../shared/directive/target-blank.module";
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AgmCoreModule } from '@agm/core';


import { OverlayModule } from '@angular/cdk/overlay';

import { LoadingComponent } from '../../shared/services/loading/loading.component';
import { LoadingInterceptor } from '../../shared/services/loading.interceptor';
import { LoadingService } from '../../shared/services/loading.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    GalleryModule.forRoot(),
    ClickOutsideModule,
    DataTablesModule,
    //Formly
    TypeaheadModule.forRoot(),
    FormlyModule.forRoot({
      types: [
        {
          name: 'button',
          component: FormlyFieldButton,
          wrappers: ['form-field'],
          defaultOptions: {
            templateOptions: {
              //btnSize: 'md',
              btnType: 'default',
              type: 'button',
            },
          },
        },
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'datepicker', component: FormlyFieldDatePicker },
        { name: 'datetimepicker', component: FormlyFieldDateTimePicker },
        {
          name: 'autocomplete', component: FormlyFieldAutocompleteComponent, wrappers: ['form-field'],
          defaultOptions: {
            templateOptions: {
              //btnSize: 'md',
              searchText: '',
              data: [],
            },
          },
        },
        {
          name: 'texteditor',
          component: FormlyFieldTextEditor,
          defaultOptions: {
            defaultValue: '',
            templateOptions: {
              config: {},
              label: 'Description'
            },
          },
        },
        { name: 'mobile', component: FormlyFieldMobile },

        //{
        //  name: 'autocomplete',
        //  component: FormlyFieldAutocompleteComponent,
        //  wrappers: ['form-field']
        //},

        {
          name: 'ng-select',
          component: FormlyFieldNgSelect,
          defaultOptions: {
            defaultValue: '',
            templateOptions: {
              options: [],
              label: 'Description',
              valueProp: '',
              labelProp: '',
              placeholder: '',
              multiple: false
            },
          },
        },
      ],
      validators: [
        { name: 'fieldMatch', validation: fieldMatchValidator },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'minlength', message: minlengthValidationMessages },

      ],
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
      ]
    }),
    FormlyBootstrapModule,
    AngularEditorModule,
    MaterialModule,
    NgSelectModule,
    MustMatchDirectiveModule,
    TargetBlankModule,
    Ng2SearchPipeModule,
    AgmCoreModule.forRoot({

      apiKey: 'AIzaSyDe4m-V0lwWSfhCD1dJshDT-XpZH2gYTDA&v=3.exp',

      libraries: ['places']

    }),
    OverlayModule,
    MatProgressSpinnerModule,

  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    GalleryModule,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    DataTablesModule,
    TypeaheadModule,
    //Formly
    FormlyModule,
    FormlyBootstrapModule,
    AngularEditorModule,
    MaterialModule,
    NgSelectModule,
    MustMatchDirectiveModule,
    TargetBlankModule,
    Ng2SearchPipeModule,
    AgmCoreModule,
    OverlayModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    SpinnerComponent,

    //Formly
    FormlyFieldButton,
    FileValueAccessor,
    FormlyFieldFile,
    FormlyFieldDatePicker,
    FormlyFieldDateTimePicker,
    PanelWrapperComponent,
    FormlyFieldAutocompleteComponent,
    FormlyFieldTextEditor,
    FormlyFieldMobile,
    FormlyFieldNgSelect,
    //MustMatchDirective,
    LoadingComponent,
  ],
  entryComponents: [LoadingComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
export function minlengthValidationMessages(err, field) {
  return `Should have atleast ${field.templateOptions.minLength} characters`;
}


export function fieldMatchValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null;
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}
