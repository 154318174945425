import { Component, OnInit } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { switchMap, tap, mergeMap } from 'rxjs/operators';
import { FieldType } from '@ngx-formly/core';

//https://stackblitz.com/run?file=src%2Fapp%2Ftypeahead-template.module.ts

@Component({
  selector: 'app-my-autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class FormlyFieldAutocompleteComponent extends FieldType implements OnInit {

  // prop = 'value.nested';
  to: any;

  //dataList: any[] = [];

  states: string[] = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
    'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois',
    'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
    'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Dakota', 'North Carolina', 'Ohio',
    'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
    'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

  //dataList: Observable<Array<any>>;

  //gettypeaheadOptionField() {
  //  return this.to.searchText;
  //}

  //arrays = [
  //  { offerCategoryName: "muthu offer", id: 1 },
  //  { offerCategoryName: "manoj offer", id: 2 },
  //  { offerCategoryName: "khan offer", id: 3 },
  //  { offerCategoryName: "code offer", id: 4 },
  //  { offerCategoryName: "geniee offer", id: 5 },
  //  { offerCategoryName: "solution offer", id: 6 }
  //];

  //search$ = new Observable((observer: Observer<string>) => {
  //  observer.next(this.formControl.value);
  //}).pipe(
  //  tap(v => console.log('Input: ' + v)),
  //  switchMap(v => {
  //    // mergeMap(v => {
  //    console.log('filtered')
  //    console.log(this.to.data)
  //    return of(this.to.data)
  //  }

  //    // of(this.dataList)
  //    //of(['foo', 'bar', 'foobar'])
  //  )
  //);

  constructor() {
    super();
  }

  ngOnInit() {
    //this.dataList = this.to.data;
  }

  getData() {
    return of(this.to.data);
  }

}










//import { Component, OnInit } from '@angular/core';
//import { Observable, Observer, of } from 'rxjs';
//import { switchMap, tap } from 'rxjs/operators';
//import { FieldType } from '@ngx-formly/core';


//////source-https://stackblitz.com/edit/angular-h3khea?file=src%2Fapp%2Fmy-autocomplete%2Fmy-autocomplete.component.html
//////https://stackoverflow.com/questions/60530180/ngx-bootstrap-typeahead-with-itemtemplate-for-object


//@Component({
//  selector: 'app-my-autocomplete',
//  templateUrl: './autocomplete.component.html'
//})
//export class FormlyFieldAutocompleteComponent extends FieldType implements OnInit {

//  prop = 'value.nested';

//  search$ = new Observable((observer: Observer<string>) => {
//    observer.next(this.formControl.value);
//  }).pipe(
//    tap(v => console.log('Input: ' + v)),
//    switchMap(v =>
//      of([
//        { value: { nested: "foo" } },
//        { value: { nested: "bar" } },
//        { value: { nested: "foobar" } }
//      ])
//      //of(['foo', 'bar', 'foobar'])
//    )
//  );

//  constructor() {
//    super();
//  }

//  ngOnInit() {
//  }

//}
