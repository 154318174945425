import { Injectable } from "@angular/core";
import { of, observable, Observable, forkJoin } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { LoaderEnabled } from '../../demo/components/loader/loader.service';
//const baseURL = "https://localhost:5001/api/User/";
const baseURL = "api/User/";
//import { environment } from '../../../environments/environment';
//const baseURL = environment.baseUrl +"/api/User/";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, public toastr: ToastrService, private _router: Router, private _activatedRoute: ActivatedRoute) { }


  duplicateData = [
    { UserId: 'User 1', UserName: 'User 1', Description: 'Sentenc4 ' },
    { UserId: 'User 2', UserName: 'User 2', Description: 'Sentenc4 ' },
    { UserId: 'User 3', UserName: 'User 3', Description: 'Sentenc4 ' },
    { UserId: 'User 511', UserName: 'User  4', Description: 'Sentenc4 ' },
  ];

  currentUser = null;
  url = "";
  checkCurrentUser() {
    //debugger
    this.currentUser = localStorage.getItem("currentUser");

    if (this.currentUser == null) {
      this._router.navigate(['/auth/signin']);
      this.toastr.info('Please Login to continue', "Login", {
        timeOut: 3000,
      });
    }

  }
  @LoaderEnabled()
  getAll(): Observable<any> {
    return this.httpClient.get(baseURL);
  }
  @LoaderEnabled()
  get(id): Observable<any> {
    return this.httpClient.get(baseURL + id);
  }
  @LoaderEnabled()
  create(data): Observable<any> {
    //debugger
    //return this.httpClient.put(`${baseURL}/${id}`, data);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.post(baseURL, data, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  update(data): Observable<any> {
    //debugger
    //return this.httpClient.put(`${baseURL}/${id}`, data);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.put(baseURL, data, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  delete(id): Observable<any> {
    //return this.httpClient.delete(`${baseURL}/${id}`);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    //return this.http.post("/api/connector/deleteConnector", connector, httpOptions).pipe(
    return this.httpClient.delete(baseURL + id).pipe(
      tap((id: any) => this.log("deleted")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  forgotPassword(email): Observable<any> {
    //debugger
    return this.httpClient.get(baseURL + "ForgotPassword/" + email).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  resetPassword(data, requestId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.put(baseURL + "ResetPassword/" + requestId, data, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  getPwdLinkExpired(requestId): Observable<any> {
    return this.httpClient.get(baseURL + "PasswordLinkExpired/" + requestId).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );

  }
  @LoaderEnabled()
  getEncryptString(val): Observable<any> {
    return this.httpClient.get(baseURL + "Encrypt/" + val);
  }
  @LoaderEnabled()
  getDecryptString(val): Observable<any> {
    return this.httpClient.get(baseURL + "Decrypt/" + val);
  }
  @LoaderEnabled()
  deleteAll(): Observable<any> {
    return this.httpClient.delete(baseURL);
  }

  searchByName(name): Observable<any> {
    return this.httpClient.get(`${baseURL}?name=${name}`);
  }

  @LoaderEnabled()
  adminLogin(data): Observable<any> {
    //debugger
    //return this.httpClient.put(`${baseURL}/${id}`, data);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.post(baseURL + "AdminLogin", data, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }

  @LoaderEnabled()
  makeUserTransaction(userName, offerCode, offerId): Observable<any> {
    return this.httpClient.get(baseURL + "MakeUserTransaction/" + userName + "/" + offerCode + "/" + offerId);
  }

  @LoaderEnabled()
  sendUserTransactionNotification(userName, offerCode, offerId): Observable<any> {
    return this.httpClient.get(baseURL + "SendUserTransactionNotification/" + userName + "/" + offerCode + "/" + offerId);
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log("UserService: " + message);
  }

  @LoaderEnabled()
  ChangePassword(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.put(baseURL + "ChangeAdminPassword/", data, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
}
