import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
//<input type="file"[formControl] = "formControl"[formlyAttributes] = "field" >
@Component({
  selector: 'formly-field-file',
  template: `
    <input type="file"[formControl] = "formControl"[formlyAttributes] = "field" >
  `,
})
export class FormlyFieldFile extends FieldType { }















//import { Component, ElementRef, ViewChild } from '@angular/core';
//import { FieldType } from '@ngx-formly/core';
////<input type="file" [formControl]="formControl" [formlyAttributes]="field">
//@Component({
//    selector: 'formly-field-file',
//    template: `


//    <div class="custom-file">
//    <input type="file" class="custom-file-input" [formControl]="formControl" [formlyAttributes]="field" (change)="onFileChange($event.target.files)">
//                  <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i> Choose file</label>
//  </div>
//  `,
//})
//export class FormlyFieldFile extends FieldType {


//    @ViewChild('labelImport')
//    labelImport: ElementRef;



//    onFileChange(files: FileList) {

//        // if (this.to.onClick) {
//        //     this.to.onClick(files);
//        // }
//        this.labelImport.nativeElement.innerText = Array.from(files)
//            .map(f => f.name)
//            .join(', ');
//        //this.fileToUpload = files.item(0);
//    }

//    // onClick($event) {
//    //     if (this.to.onClick) {
//    //         this.to.onClick($event);
//    //     }
//    // }


//}
