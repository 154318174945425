import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-datepicker',
    template: `
<div class="form-group">
   <label>{{to.label}}</label>
   <input class="form-control" type="date" [(min)]="to.minDate" [(max)]="to.maxDate" [formControl]="formControl" [formlyAttributes]="field">
<div>
  `,
})
export class FormlyFieldDatePicker extends FieldType {

}
