<!--<mat-spinner></mat-spinner>-->

<div class="loaderout">

  <div class="progress_layer"></div>
  <div id="loader"></div>

  <p class="pclass">Please wait</p>
  <span><i></i><i></i></span>

</div>
