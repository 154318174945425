import { Injectable, OnInit } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

//const NavigationItems = [
//  {
//    id: 'navigation',
//    title: 'Navigation',
//    type: 'group',
//    icon: 'icon-navigation',
//    children: [
//      {
//        id: 'dashboard',
//        title: 'Dashboard',
//        type: 'item',
//        url: '/dashboard/default',
//        icon: 'feather icon-home',
//        classes: 'nav-item',
//      }
//    ]
//  },
//  {
//    id: 'ui-element',
//    title: 'UI ELEMENT',
//    type: 'group',
//    icon: 'icon-ui',
//    hidden: true,
//    children: [
//      {
//        id: 'basic',
//        title: 'Component',
//        type: 'collapse',
//        icon: 'feather icon-box',
//        children: [
//          {
//            id: 'button',
//            title: 'Button',
//            type: 'item',
//            url: '/basic/button'
//          },
//          {
//            id: 'badges',
//            title: 'Badges',
//            type: 'item',
//            url: '/basic/badges'
//          },
//          {
//            id: 'breadcrumb-pagination',
//            title: 'Breadcrumb & Pagination',
//            type: 'item',
//            url: '/basic/breadcrumb-paging'
//          },
//          {
//            id: 'collapse',
//            title: 'Collapse',
//            type: 'item',
//            url: '/basic/collapse'
//          },
//          {
//            id: 'tabs-pills',
//            title: 'Tabs & Pills',
//            type: 'item',
//            url: '/basic/tabs-pills'
//          },
//          {
//            id: 'typography',
//            title: 'Typography',
//            type: 'item',
//            url: '/basic/typography'
//          }
//        ]
//      }
//    ]
//  },
//  {
//    id: 'forms',
//    title: 'Forms & Tables',
//    type: 'group',
//    icon: 'icon-group',
//    hidden: true,
//    children: [
//      {
//        id: 'forms-element',
//        title: 'Form Elements',
//        type: 'item',
//        url: '/forms/basic',
//        classes: 'nav-item',
//        icon: 'feather icon-file-text'
//      },
//      {
//        id: 'tables',
//        title: 'Tables',
//        type: 'item',
//        url: '/tables/bootstrap',
//        classes: 'nav-item',
//        icon: 'feather icon-server'
//      }
//    ]
//  },
//  {
//    id: 'chart-maps',
//    title: 'Chart & Maps',
//    type: 'group',
//    icon: 'icon-charts',
//    hidden: true,
//    children: [
//      {
//        id: 'charts',
//        title: 'Charts',
//        type: 'item',
//        url: '/charts/morris',
//        classes: 'nav-item',
//        icon: 'feather icon-pie-chart'
//      }
//    ]
//  },

//  {
//    id: 'billing',
//    title: 'Billing',
//    type: 'group',
//    icon: 'icon-ui',
//    hidden: true,
//    children: [
//      {
//        id: 'manage-billing',
//        title: 'Manage Billing',
//        type: 'collapse',
//        icon: 'feather icon-box',
//        children: [
//          {
//            id: 'invoice',
//            title: 'Invoice',
//            type: 'item',
//            url: '/billing/invoice'
//          },
//        ]
//      }
//    ]
//  },

//  {
//    id: 'pages',
//    title: 'Pages',
//    type: 'group',
//    icon: 'icon-pages',
//    children: [
//      {
//        id: 'driver',
//        title: 'Driver Management',
//        type: 'item',
//        url: '/driver',
//        classes: 'nav-item',
//        icon: 'feather icon-user'
//      },
//      {
//        id: 'Pumpman',
//        title: 'Pump Operator Management',
//        type: 'item',
//        url: '/pumpoperator',
//        classes: 'nav-item',
//        icon: 'feather icon-user'
//      },

//      {
//        id: 'marketing-executive',
//        title: 'Marketing Executive',
//        type: 'item',
//        url: '/marketing-executive',
//        classes: 'nav-item',
//        icon: 'feather icon-user'
//      },

//      {
//        id: 'customer',
//        title: 'Lead Management',
//        type: 'item',
//        url: '/lead',
//        classes: 'nav-item',
//        icon: 'feather icon-user'
//      },
//      {
//        id: 'vehicle',
//        title: 'Vehicle Management',
//        type: 'collapse',
//        icon: 'feather icon-calendar',
//        children: [
//          {
//            id: 'vehicle',
//            title: 'Manage Vehicle',
//            type: 'item',
//            url: '/vehicle/manage',
//          },
//          {
//            id: 'vehicle-track',
//            title: 'Vehicle Tracking',
//            type: 'item',
//            url: '/vehicle/track',
//          },

//        ]
//      },

//      {
//        id: 'user',
//        title: 'User Management',
//        type: 'item',
//        url: '/user',
//        classes: 'nav-item',
//        icon: 'feather icon-user'
//      },
//      {
//        id: 'login',
//        title: 'Login Management',
//        type: 'item',
//        url: '/login-management',
//        classes: 'nav-item',
//        icon: 'feather icon-user'
//      },

//      {
//        id: 'order',
//        title: 'Order Management',
//        type: 'collapse',
//        icon: 'feather icon-calendar',
//        children: [
//          {
//            id: 'pending',
//            title: 'Pending Orders',
//            type: 'item',
//            url: '/order/pending',
//          },
//          {
//            id: 'accepted',
//            title: 'Accepted Orders',
//            type: 'item',
//            url: '/order/accepted',
//          },
//          {
//            id: 'cancelled',
//            title: 'Cancelled Orders',
//            type: 'item',
//            url: '/order/cancelled',
//          },
//          {
//            id: 'rejected',
//            title: 'Rejected Orders',
//            type: 'item',
//            url: '/order/rejected',
//          },

//          {
//            id: 'delivered',
//            title: 'Delivered Orders',
//            type: 'item',
//            url: '/order/delivered',
//          },


//        ]
//      },

//      {
//        id: 'purchase',
//        title: 'Purchase Management',
//        type: 'collapse',
//        icon: 'feather icon-calendar',
//        children: [
//          {
//            id: 'purchase-order',
//            title: 'Generate PO',
//            type: 'item',
//            url: '/purchase-order',
//          },
//           {
//id: 'invoice',
//  title: 'Confirm PO',
//    type: 'item',
//      url: '/invoice',
//          },
//        ]
//      },
//      {
//        id: 'expenses',
//        title: 'Expenses Management',
//        type: 'item',
//        url: '/expenses',
//        classes: 'nav-item',
//        icon: 'feather icon-credit-card'
//      },


//      {
//        id: 'master',
//        title: 'Master',
//        type: 'collapse',
//        icon: 'feather icon-lock',
//        hidden: true,
//        children: [
//          {
//            id: 'category',
//            title: 'Category',
//            type: 'item',
//            url: '/category',
//          },
//          {
//            id: 'product',
//            title: 'Product',
//            type: 'item',
//            url: '/product',
//          },
//          {
//            id: 'waiter',
//            title: 'Waiter',
//            type: 'item',
//            url: '/waiter',
//          },
//          {
//            id: 'customer',
//            title: 'Customer',
//            type: 'item',
//            url: '/customer',
//          },
//          {
//            id: 'table',
//            title: 'Table',
//            type: 'item',
//            url: '/table',
//          },
//          {
//            id: 'table-group',
//            title: 'Table Group',
//            type: 'item',
//            url: '/table-group',
//          },

//        ]
//      },
//      {
//        id: 'auth',
//        title: 'Authentication',
//        type: 'collapse',
//        icon: 'feather icon-lock',
//        hidden: true,
//        children: [
//          {
//            id: 'signup',
//            title: 'Sign up',
//            type: 'item',
//            url: '/auth/signup',
//            target: true,
//            breadcrumbs: false
//          },
//          {
//            id: 'signin',
//            title: 'Sign in',
//            type: 'item',
//            url: '/auth/signin',
//            target: true,
//            breadcrumbs: false
//          },
//          {
//            id: 'forgot-password',
//            title: 'Forgot Password',
//            type: 'item',
//            url: '/auth/forgot-password',
//            target: true,
//            breadcrumbs: false
//          },
//          {
//            id: 'reset-password',
//            title: 'Reset Password',
//            type: 'item',
//            url: '/auth/reset-password',
//            target: true,
//            breadcrumbs: false
//          }
//        ]
//      },

//      {
//        id: 'setting',
//        title: 'Settings',
//        type: 'item',
//        url: '/setting',
//        classes: 'nav-item',
//        icon: 'feather icon-settings'
//      },
//      {
//        id: 'enquiry',
//        title: 'Enquiry (Memo Register)',
//        type: 'item',
//        url: '/enquiry',
//        classes: 'nav-item',
//        icon: 'feather icon-file-plus'
//      },
//      {
//        id: 'slider',
//        title: 'Slider Management',
//        type: 'item',
//        url: '/slider',
//        classes: 'nav-item',
//        icon: 'feather icon-sidebar'
//      },

//      {
//        id: 'sample-page',
//        title: 'Sample Page',
//        type: 'item',
//        url: '/sample-page',
//        classes: 'nav-item',
//        icon: 'feather icon-sidebar',
//        hidden: true,
//      },
//      {
//        id: 'disabled-menu',
//        title: 'Disabled Menu',
//        type: 'item',
//        url: 'javascript:',
//        classes: 'nav-item disabled',
//        icon: 'feather icon-power',
//        external: true,
//        hidden: true,
//      },
//      {
//        id: 'buy_now',
//        title: 'Buy Now',
//        type: 'item',
//        icon: 'feather icon-book',
//        classes: 'nav-item',
//        url: 'https://codedthemes.com/item/datta-able-angular/',
//        target: true,
//        external: true,
//        hidden: true,
//      }
//    ]
//  }
//];

const NavigationItems_Admin = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard/default',
        icon: 'feather icon-home',
        classes: 'nav-item',
      }
    ]
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'icon-pages',
    children: [
      {
        id: 'shop',
        title: 'Manage Customers',
        type: 'item',
        url: '/shop',
        classes: 'nav-item',
        icon: 'feather icon-user'
      },

      {
        id: 'pending-shop',
        title: 'Pending Customers',
        type: 'item',
        url: '/pending-shop',
        classes: 'nav-item',
        icon: 'feather icon-user'
      },
      {
        id: 'account',
        title: 'Customer Accounts',
        type: 'item',
        url: '/account',
        classes: 'nav-item',
        icon: 'feather icon-bar-chart-2'
      },
      {
        id: 'employee',
        title: 'Manage Collection Boy',
        type: 'item',
        url: '/employee',
        classes: 'nav-item',
        icon: 'feather icon-user'
      },
      {
        id: 'product',
        title: 'Report',
        type: 'item',
        url: '/product',
        classes: 'nav-item',
        icon: 'feather icon-airplay'
      },
      {
        id: 'deduction-report',
        title: 'Deduction Report',
        type: 'item',
        url: '/deduction-report',
        classes: 'nav-item',
        icon: 'feather icon-link-2'
      },
      {
        id: 'customer-summery-report',
        title: 'Summary Report',
        type: 'item',
        url: '/customer-summery-report',
        classes: 'nav-item',
        icon: 'feather icon-grid'
      },
      //{
      //  id: 'demo',
      //  title: 'Demo Page',
      //  type: 'item',
      //  url: '/demo',
      //  classes: 'nav-item',
      //  icon: 'feather icon-book'
      //},acceptedpaymentrequest
      {
        id: 'paymentrequest',
        title: 'Payment Request',
        type: 'item',
        url: '/paymentrequest',
        classes: 'nav-item',
        icon: 'feather icon-slack'
      },
      {
        id: 'acceptedpaymentrequest',
        title: 'Accepted Payment Request',
        type: 'item',
        url: '/acceptedpaymentrequest',
        classes: 'nav-item',
        icon: 'feather icon-book'
      },
      {
        id: 'goldloanrequest',
        title: 'Gold Loan Request',
        type: 'item',
        url: '/goldloanrequest',
        classes: 'nav-item',
        icon: 'feather icon-target'
      },
      {
        id: 'goldsellingrequest',
        title: 'Gold Selling Request',
        type: 'item',
        url: '/goldsellingrequest',
        classes: 'nav-item',
        icon: 'feather icon-chrome'
      },
      {
        id: 'changepassword',
        title: 'Change Password',
        type: 'item',
        url: '/changepassword',
        classes: 'nav-item',
        icon: 'feather icon-book'
      },
      //{
      //  id: 'forgot-password',
      //  title: 'Forgot Password',
      //  type: 'item',
      //  url: '/auth/forgot-password',
      //  target: true,
      //  breadcrumbs: false
      //},
      //{
      //  id: 'reset-password',
      //  title: 'Reset Password',
      //  type: 'item',
      //  url: '/auth/reset-password',
      //  target: true,
      //  breadcrumbs: false
      //},
      //{
      //  id: 'closed-collection',
      //  title: 'Closed Collections',
      //  type: 'item',
      //  url: '/closed-collection',
      //  classes: 'nav-item',
      //  icon: 'feather icon-lock'
      //},
      //{
      //  id: 'collection',
      //  title: 'Manage Collection Filter',
      //  type: 'item',
      //  url: '/collection',
      //  classes: 'nav-item',
      //  icon: 'feather icon-airplay'
      //},
      //{
      //  id: 'employee-track',
      //  title: 'Track Employee',
      //  type: 'item',
      //  url: '/employee-track',
      //  classes: 'nav-item',
      //  icon: 'feather icon-map-pin'
      //},

      //{
      //  id: 'report',
      //  title: 'Reports',
      //  type: 'collapse',
      //  icon: 'feather icon-book',
      //  children: [
      //    {
      //      id: 'product',
      //      title: 'Day Wise Collection Report',
      //      type: 'item',
      //      url: '/product',
      //      classes: 'nav-item',
      //      icon: 'feather icon-airplay'
      //    },
      //    {
      //      id: 'daily-collection-report',
      //      title: ' Payment Method Report',
      //      type: 'item',
      //      url: '/daily-collection-report',
      //      classes: 'nav-item',
      //      icon: 'feather icon-book'
      //    },
      //  ]
      //},

      {
        id: 'auth',
        title: 'Authentication',
        type: 'collapse',
        icon: 'feather icon-lock',
        hidden: true,
        children: [
          {
            id: 'signup',
            title: 'Sign up',
            type: 'item',
            url: '/auth/signup',
            target: true,
            breadcrumbs: false
          },
          {
            id: 'signin',
            title: 'Sign in',
            type: 'item',
            url: '/auth/signin',
            target: true,
            breadcrumbs: false
          },
          {
            id: 'forgot-password',
            title: 'Forgot Password',
            type: 'item',
            url: '/auth/forgot-password',
            target: true,
            breadcrumbs: false
          },
          {
            id: 'reset-password',
            title: 'Reset Password',
            type: 'item',
            url: '/auth/reset-password',
            target: true,
            breadcrumbs: false
          }
        ]
      },


      {
        id: 'slider',
        title: 'Slider Management',
        type: 'item',
        url: '/slider',
        classes: 'nav-item',
        icon: 'feather icon-sidebar',
        hidden: true
      },

    ]
  }
];



const NavigationItems_EntranceTicketManagement = [
  //{
  //  id: 'navigation',
  //  title: 'Navigation',
  //  type: 'group',
  //  icon: 'icon-navigation',
  //  children: [
  //    {
  //      id: 'dashboard',
  //      title: 'Dashboard',
  //      type: 'item',
  //      url: '/dashboard/default',
  //      icon: 'feather icon-home',
  //      classes: 'nav-item',
  //    }
  //  ]
  //},
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'icon-pages',
    children: [

      {
        id: 'entrance-ticket',
        title: 'Entrance Ticket',
        type: 'item',
        url: '/entrance-ticket',
        classes: 'nav-item',
        icon: 'feather icon-calendar',
        breadcrumbs: false
      },
    ]
  }
];


const NavigationItems_GameTicketManagement = [
  //{
  //  id: 'navigation',
  //  title: 'Navigation',
  //  type: 'group',
  //  icon: 'icon-navigation',
  //  children: [
  //    {
  //      id: 'dashboard',
  //      title: 'Dashboard',
  //      type: 'item',
  //      url: '/dashboard/default',
  //      icon: 'feather icon-home',
  //      classes: 'nav-item',
  //    }
  //  ]
  //},
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'icon-pages',
    children: [

      {
        id: 'game-ticket',
        title: 'Game Ticket',
        type: 'item',
        url: '/game-ticket',
        classes: 'nav-item',
        icon: 'feather icon-calendar',
        breadcrumbs: false
      },
    ]
  }
];




@Injectable()
export class NavigationItem {

  currentUserDetails: any = {};
  isAdminUser = false;





  //get_OLD() {
  //  return NavigationItems;
  //}

  get() {
    this.currentUserDetails = JSON.parse(localStorage.getItem('lwbcurrentUserDetails'));
    if (this.currentUserDetails != null) {
      if (this.currentUserDetails['isAdmin'] == 1) {//Admin

        return NavigationItems_Admin;
      }
      else {

        if (this.currentUserDetails['userType'] == 1) {//EntranceTicketManagement    
          return NavigationItems_EntranceTicketManagement;

        }
        else if (this.currentUserDetails['userType'] == 2) {//GameTicketManagement      
          return NavigationItems_GameTicketManagement;

        }
      }
    }
  }
}
