import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-datetimepicker',
  template: `
<div class="form-group">
   <label>{{to.label}}</label>
   <input class="form-control" type="datetime-local" [(min)]="to.minDate" [(max)]="to.maxDate" [formControl]="formControl" [formlyAttributes]="field">
</div>
  `,
})
export class FormlyFieldDateTimePicker extends FieldType {

}
