import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  //  template: `
  //<div class="form-group">
  //   <label>{{to.label}}</label>
  //   <input class="form-control" (keypress)="numberOnly($event)" maxLength="10" type="text" [formControl]="formControl" [formlyAttributes]="field">
  //</div>
  // `,

  template: `
    <div class="form-group" [class.error]="showError">
         <label>{{to.label}}</label>
         <ng-select [items]="to.options"
                   [bindLabel]="to.labelProp"
                   [bindValue]="to.valueProp"
                   [placeholder]="to.placeholder"               
                   [multiple]="to.multiple"
                   [closeOnSelect]="to.closeOnSelect"
[formControl]="formControl" [formlyAttributes]="field"
                   >
        </ng-select>  
      <formly-validation-message *ngIf="showError"
        [fieldForm]="formControl" 
        [field]="field"></formly-validation-message>
    </div>
 `,
  styles: [`
.ng-select .ng-select-container {
    background: #f4f7fa!important;
    padding: 7px 22px!important;
    font-size: 14px!important;
}

  .error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .error input {
    border-color: #dc3545;
  }
 `]

})
////https://ng-select.github.io/ng-select#/multiselect-checkbox
export class FormlyFieldNgSelect extends FieldType {
  to: any;
}
