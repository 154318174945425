import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[target=_blank]',
})
export class TargetBlankDirective {
  @HostBinding('attr.rel') rel = 'noopener noreferrer';
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


//import { TargetBlankDirective } from './target-blank.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TargetBlankDirective],
  exports: [TargetBlankDirective]
})

export class TargetBlankModule { }
