import { Injectable } from "@angular/core";
import { of, observable, Observable, forkJoin } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { LoaderEnabled } from '../../demo/components/loader/loader.service';
//const baseURL = "https://localhost:6001/api/Shop/";
const baseURL = "api/Shop/";
//import { environment } from '../../../environments/environment';
//const baseURL = environment.baseUrl + "/api/Shop/";


@Injectable({
  providedIn: 'root'
})
export class ShopService {

  mock: boolean = false;
  constructor(private httpClient: HttpClient) { }


  duplicateData = [
    { ServiceId: 'Services1', ServiceName: 'Services 1', Description: 'Sentenc4 ' },
    { ServiceId: 'Services2', ServiceName: 'Services 2', Description: 'Sentenc4 ' },
    { ServiceId: 'Services3', ServiceName: 'Services 3', Description: 'Sentenc4 ' },
  ];
  @LoaderEnabled()
  CloseCollection(objCloseCollectionInput): Observable<any> {
    //debugger
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.post(baseURL + "CloseAllCollection", objCloseCollectionInput, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  getAll(): Observable<any> {
    return this.httpClient.get(baseURL);
  }

  @LoaderEnabled()
  getAllNewCustomerRequesties(): Observable<any> {
    return this.httpClient.get(baseURL + "ListAllNewCustomerRequesties");
  }

  @LoaderEnabled()
  get(id): Observable<any> {
    return this.httpClient.get(baseURL + id);
  }
  @LoaderEnabled()
  create(data): Observable<any> {
    //debugger
    //return this.httpClient.put(`${baseURL}/${id}`, data);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.post(baseURL, data, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  update(data): Observable<any> {
    //debugger
    //return this.httpClient.put(`${baseURL}/${id}`, data);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.httpClient.put(baseURL, data, httpOptions).pipe(
      tap((data: any) => this.log("updated")),
      catchError(this.handleError<any>(""))
    );
  }
  @LoaderEnabled()
  delete(id): Observable<any> {
    //return this.httpClient.delete(`${baseURL}/${id}`);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    //return this.http.post("/api/connector/deleteConnector", connector, httpOptions).pipe(
    return this.httpClient.delete(baseURL + id).pipe(
      tap((id: any) => this.log("deleted")),
      catchError(this.handleError<any>(""))
    );
  }

  deleteAll(): Observable<any> {
    return this.httpClient.delete(baseURL);
  }


  searchByName(name): Observable<any> {
    return this.httpClient.get(`${baseURL}?name=${name}`);
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log("ShopService: " + message);
  }
}
