
<!--<div class="loader-wrapper" *ngIf="loaderEnabled">
  
  <div class="backdrop"></div>
  
  <div class="spinner"></div>

</div>-->

<div class="loaderout" *ngIf="loaderEnabled">

    <div class="progress_layer"></div>
    <div id="loader"></div>

    <p class="pclass">Please wait</p>
    <span><i></i><i></i></span>

</div>