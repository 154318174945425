import { Injectable } from "@angular/core";
import { of, observable, Observable, forkJoin } from "rxjs";
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { FormlyFieldConfig } from "@ngx-formly/core";

////const baseURL = "https://localhost:5001/api/Product/";
const baseURL = "api/Dashboard/";

//import { environment } from '../../../environments/environment';
//const baseURL = environment.baseUrl + "/api/Dashboard/";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private httpClient1: HttpClient;
  constructor(private httpClient: HttpClient, httpBackend: HttpBackend) {
    this.httpClient1 = new HttpClient(httpBackend);
  }


  getDashboardDetailsForNoLoader(): Observable<any> {
    return this.httpClient1.get(baseURL + 'LoadDashboardDetails');
  }

  getDashboardDetails(): Observable<any> {
    return this.httpClient.get(baseURL + 'LoadDashboardDetails');
  }



  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log("DashboardService: " + message);
  }
}
