import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
    selector: 'formly-field-texteditor',
    template: `
<div>
   <label>{{to.label}}</label>
   <angular-editor [formControl]="formControl" [formlyAttributes]="field" [config]="config"></angular-editor>
<div>
  `,
})
export class FormlyFieldTextEditor extends FieldType {
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
            ['bold']
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ]
    };
}
