import { Component, DoCheck, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { DattaConfig } from '../../../../../app-config';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
const baseURL = environment.baseUrl;
import { ViewChild, ElementRef } from '@angular/core';
import { Howl, Howler } from 'howler';
import { NotificationService } from 'src/app/shared/services/notification.service';


@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck, OnDestroy {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;


  notificationList: Array<any> = [];
  selectedNotification: any = {};
  @ViewChild('myAudio', { static: true }) myAudio: ElementRef;

  notificationCount = 0;

  public viewFlag = false;

  public hubConnection: HubConnection;

  constructor(config: NgbDropdownConfig, public _router: Router,
    private toastr: ToastrService, private _notificationService: NotificationService) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
  }

  ngOnDestroy(): void {
    //// Do not forget to unsubscribe the event
    //this.dtTrigger.unsubscribe();
    //this.subscription && this.subscription.unsubscribe();
    if (this.hubConnection != undefined && this.hubConnection != null)
      this.hubConnection.off("LoadNotifications");
  }

  currentUserDetails: any = {};
  isAdminUser = false;
  ngOnInit() {

    this.currentUserDetails = JSON.parse(localStorage.getItem('lwbcurrentUserDetails'));
    this.isAdminUser = false;
    if (this.currentUserDetails != null) {

      if (this.currentUserDetails['isAdmin'] == 1) {
        this.isAdminUser = true;
        this.loadSignalrData();
      }

    }

    if (this.currentUserDetails != null) {

      if (this.currentUserDetails['isAdmin'] == 1) {
        this.isAdminUser = true;
        this.loadSignalrData();
      }


    }

  }
  public DoLogOut() {
    localStorage.removeItem('lwbcurrentUserDetails');
    this._router.navigate(['/auth/signin']);
  }

  public loadSignalrData() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      //.withUrl(baseURL + "/notify")
      .withUrl("/notify")
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start().then(function () {
      console.log('SignalR Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });

    this.hubConnection.on("LoadNotifications", (notificationType) => {
      //debugger
      this.getAllNotification();
    });
    this.getAllNotification();
  }

  onChange($event) {
    //if ($event) {
    //  this.newOrderCount = 0;
    //} else {
    //  //alert('close');
    //}
  }

  public getAllNotification(): void {
    //debugger
    this._notificationService.getAllForNoLoader()
      .subscribe(
        response => {
          if (response.isSucceeded) {
            //this.toastr.success('Login Successfully!', this.model['userName'], {
            //  timeOut: 3000,
            //});          
            this.notificationList = response.gNotificationList
            this.notificationCount = this.notificationList.length > 0 ? this.notificationList.length : 0;
            if (this.notificationList.length > 0) {
              try {
                this.myAudio.nativeElement.play();
              }
              catch{

              }
            }

          }
          else {
            this.notificationList = [];
            this.notificationCount = 0;
            //this.toastr.warning(response.errorNote, "Error", {
            //  timeOut: 3000,
            //});
          }

        },
        error => {
          console.log(error);
          //this.toastr.error(error, "Error", {
          //  timeOut: 3000,
          //});
        });
  }

  onViewNotification(objNotification) {
    this.selectedNotification = objNotification;
    this._notificationService.onClearNotification(objNotification.notificationId)
      .subscribe(
        response => {
          if (response.isSucceeded) {
            //this.toastr.success('Login Successfully!', this.model['userName'], {
            //  timeOut: 3000,
            //});
            //this.orderNotificationList = response.gOrderNotificationList;
            //debugger
            //this.setDTData();
            //this.rerender();
            this.getAllNotification();
            this.selectedNotification = response.gNotification;

            if (this.selectedNotification != null) {
              if (this.selectedNotification.type == 1) {//InvestorInvestedNewPlan
                this._router.navigate(["/investor-plan"]);
              }
              if (this.selectedNotification.type == 2) {//InvestorWithdrawnNewRequest
                this._router.navigate(["/withdraw-request"]);
              }
            }
          }
          else {
            this.selectedNotification = null;
            ////this.toastr.warning(response.errorNote, "Error", {
            ////  timeOut: 3000,
            ////});
            //if (this.viewFlag == true) {
            //  this._router.navigate(["/order/pending"]);
            //}
          }

        },
        error => {
          console.log(error);
          //this.toastr.error(error, "Error", {
          //  timeOut: 3000,
          //});
        });
  }


  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('datta-rtl')) {
      this.dattaConfig['rtl-layout'] = true;
    } else {
      this.dattaConfig['rtl-layout'] = false;
    }
  }











}
